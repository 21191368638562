<template>
  <div class="order pb-16 sm:pb-0">
    <div class="order-body flex justify-between">
      <div class="order-item">
        <div class="crypto flex items-center">
          <div class="crypto-img">
            <img src="img/cryptocurrency/btc.svg" alt="">
          </div>
          <div class="crypto-name">{{ formatCrypto(currentCoin) }}</div>
        </div>
        <div class="crypto-price">
          <div class="crypto-price__item flex mb-6">
            <div class="flex items-center">
              <i class="icon-plus-btn"></i>
              <p class="text-green">{{ $t('order.buy_price') }}</p>
            </div>
            <div class="ml-auto price">{{ liveData.average_buy_price }}</div>
          </div>
          <div class="crypto-price__item flex">
            <div class="flex items-center">
              <i class="icon-minus-btn"></i>
              <p class="text-red">{{ $t('order.sell_price') }}</p>
            </div>
            <div class="ml-auto price">{{ liveData.average_sell_price }}</div>
          </div>
        </div>
      </div>
      <div class="order-item">
        <div class="order-item__header flex">
          <div class="name">{{ $t('general.available_balance') }}:</div>
          <div class="count ml-auto text-white">{{ balanceFormat }}</div>
        </div>
        <div class="order-item__field">
          <div class="lable">{{ $t('general.deposite') }}</div>
          <input class="w-full value bg-gray border-gray placeholder-gray-light text-white focus:outline-none"
            id="deposite" type="number" min="0"
            :oninput="this.deposite = !!this.deposite && Math.abs(this.deposite) >= 0 ? Math.abs(this.deposite) : null"
            :placeholder="$t('general.amount')" required v-model.number="deposite">
        </div>
        <div class="order-item__field mt-auto">
          <div class="lable">{{ $t('general.multiple') }}</div>
          <AppSelect :i18n="false" class="" :lable="multiple" :options="multipleOptions" @select="selectMultiple" />
        </div>
      </div>
      <div class="order-item">
        <div class="order-item__header flex">
          <div class="name">{{ $t('order.total_order_value') }}:</div>
          <div class="count ml-auto text-white">123123123</div>
        </div>
        <div class="order-item__field flex items-center">
          <h4>{{ $t('order.profit_stop') }}</h4>
          <div class="stop flex items-center flex-row ml-auto w-36">
            <i class="icon-minus-empty" @click="profit--"></i>
            <input class="stop-profit w-full bg-gray border-gray placeholder-gray-light text-white focus:outline-none"
              id="profit" type="number" required placeholder="0" step="0.1" min="0"
              :oninput="this.profit = !!this.profit && Math.abs(this.profit) >= 0 ? Math.abs(this.profit) : null"
              v-model.number="profit">
            <i class="icon-plus-empty" @click="profit++"></i>
          </div>
        </div>
        <div class="order-item__field flex items-center mt-auto">
          <h4>{{ $t('order.loss_stop') }}</h4>
          <div class="stop flex items-center flex-row ml-auto w-36">
            <i class="icon-minus-empty" @click="loss--"></i>
            <input class="stop-profit w-full bg-gray border-gray placeholder-gray-light text-white focus:outline-none"
              id="loss" type="number" required placeholder="0" step="0.1" min="0"
              :oninput="this.loss = !!this.loss && Math.abs(this.loss) >= 0 ? Math.abs(this.loss) : null"
              v-model.number="loss">
            <i class="icon-plus-empty" @click="loss++"></i>
          </div>
        </div>
      </div>
    </div>
    <span class="text-red" v-if="errorMessage">{{ errorMessage }}</span>
    <div class="order-footer flex">
      <div class="switch flex items-center">
        <input type="checkbox" id="switch" v-model="support" />
        <label for="switch"></label>
        <p class="ml-3.5">{{ $t('order.support_overnight_protection') }}</p>
      </div>
      <div class="order-footer__btn flex md:ml-auto">
        <button class="btn btn-blue py-2.5 md:px-8" @click="createOption(0)">{{ $t('order.place_call_order') }}</button>
        <button class="btn btn-yellow ml-5 py-2.5 md:px-8" @click="createOption(1)">{{ $t('order.place_put_order')
          }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import AppSelect from '@/components/ui/AppSelect'

export default {
  name: 'Order',
  data: () => ({
    support: false,
    multiple: 'x10',
    multipleOptions: ['x10', 'x20', 'x30'],
    profit: 0,
    loss: 0,
    deposite: 0,
    errorMessage: undefined
  }),
  components: {
    AppSelect
  },
  async created () {
    await this.$store.dispatch('getUserInfo')
    // this.profit = this.liveData.average_buy_price
  },
  computed: {
    currentCoin () {
      return this.$store.state.dashboard.currentCurrencyType
    },
    liveData () {
      return this.$store.state.dashboard.liveData[this.currentCoin] || {}
    },
    balanceFormat () {
      return Number((this.$store.state.auth.user.balance || 0).toFixed(3))
    }
  },
  methods: {
    createOption (type) {
      this.errorMessage = undefined
      const payload = {
        type,
        multiply: parseInt(this.multiple.split('x')[1]),
        currency_type: this.currentCoin,
        deposit: this.deposite,
        stop_profit_price: this.profit,
        stop_loss_price: this.loss,
        support_over_night: this.support
      }
      this.$store.dispatch('create_option', payload)
        .then(res => this.$router.push('/trade'))
        .catch(err => {
          this.errorMessage = err.response.data
        })
    },
    selectMultiple (option) {
      this.multiple = option
    },
    formatCrypto (str) {
      return `${str.split('USDT')[0]}/USDT`
    }
  }
}
</script>

<style scoped>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
